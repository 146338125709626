import React, { useState } from 'react'
import { HStack, IconButton, StatusPopup, useStatusPopup } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { DropdownSelectCell } from '@src/components/Table/AdvancedCells/DropdownSelectCell/DropdownSelectCell'
import {
  TempPolicyAssignmentInterface,
  TimeOffPolicyTableInterface,
} from '@src/interfaces/timeOff'
import { IdAndName } from '@src/interfaces'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '../routes'
import { timeOffPoliciesRequests } from '@src/api/timeOff'
import { navigateTo } from '@src/actions/RouterActions'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export const manageTimeOffPoliciesGroupColumn = (
  groups: IdAndName[],
  onGroupChange: (row: TimeOffPolicyTableInterface, option: IdAndName | null) => void,
): ColumnInterface<TimeOffPolicyTableInterface> => ({
  type: CellTypes.insert,
  idPoint: 'group',
  dataPoint: 'group',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Group',
  insert: ({ data }) => {
    return (
      <RadioSelectInput
        onChange={option => onGroupChange(data, option)}
        options={groups.map(value => ({
          label: value.name,
          value,
        }))}
        renderInput={(open, setOpen, ref) => (
          <DropdownSelectCell
            label={data.group?.name || 'Select group'}
            open={open}
            onClick={() => setOpen(!open)}
            ref={ref}
          />
        )}
      />
    )
  },
})

interface ManageTimeOffPoliciesActionsProps {
  canEdit: boolean
  id: number
}

const ManageTimeOffPoliciesActions = ({
  canEdit,
  id,
}: ManageTimeOffPoliciesActionsProps) => {
  const [copyPending, setCopyPending] = useState(false)

  const statusPopup = useStatusPopup()

  const onCopy = () => {
    setCopyPending(true)

    timeOffPoliciesRequests
      .get({ id: `${id}` })
      .then(response => {
        const { id: notNeededId, ...newValues } = response.data
        const url = pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.EDIT.BASICS)

        workspaceLocalStorage.setItem(url, JSON.stringify(newValues))
        navigateTo(url)
      })
      .catch(error => {
        setCopyPending(false)

        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to copy policy</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <>
      <HStack space="s-8">
        {canEdit && (
          <TableActionButton
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.EDIT.BASICS, { id })}
          >
            <IconButton useIcon="Pencil" size={16} tapArea={20} />
          </TableActionButton>
        )}
        <TableActionButton onClick={onCopy} pending={copyPending}>
          <IconButton useIcon="Copy" size={16} tapArea={20} />
        </TableActionButton>
      </HStack>
    </>
  )
}

export const manageTimeOffPoliciesActionColumn = (
  canEdit: boolean,
): ColumnInterface<TimeOffPolicyTableInterface> => ({
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
  insert: ({ data }) => <ManageTimeOffPoliciesActions canEdit={canEdit} id={data.id} />,
})

export const tempPolicyAssignmentEmployeeColumn: ColumnInterface<TempPolicyAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee',
    insert: ({ data }) => <UserWithAvatar {...data.employee} />,
  }

export const tempPolicyAssignmentEmailColumn: ColumnInterface<TempPolicyAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.email',
    dataPoint: 'employee.email',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Email',
    insert: ({ data }) => <>{data.employee.email || '-'}</>,
  }

export const tempPolicyAssignmentLocationColumn: ColumnInterface<TempPolicyAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee.location.id',
    dataPoint: 'employee.location.location_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Location',
  }

export const tempPolicyAssignmentDepartmentColumn: ColumnInterface<TempPolicyAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee.department.id',
    dataPoint: 'employee.department.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Department',
  }

export const tempPolicyAssignmentPolicyColumn: ColumnInterface<TempPolicyAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'policy.id',
    dataPoint: 'policy.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Policy',
  }
