import {
  InterviewStageWithoutRoundInterface,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import React, { useState } from 'react'
import { setOnlineTestResult } from '@src/api/hiringProcess'
import SideBar from '@components/SideBar/SideBar'
import { DetailsCell, Group, Header, Link, MoreBar, VStack } from '@revolut/ui-kit'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import OnlineTestResultWidget from '@src/features/InterviewTool/OnlineTestResultWidget'
import { FormPreview } from '@components/FormPreview/FormPreview'
import CopyClipboardButton from '@components/CopyClipboardButton/CopyClipboardButton'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'

type Props = {
  onClose: () => void
  stage?: InterviewStageWithoutRoundInterface
  roundId?: number
  onRefresh: () => void
  onOpenSidebar?: (
    data: InterviewStageWithoutRoundInterface,
    mode?: ScheduleSidebarModeType,
  ) => void
  isOpen?: boolean
  candidateId?: number
}
const OnlineTestSummarySidebar = ({
  isOpen,
  onClose,
  stage,
  roundId,
  onRefresh,
  onOpenSidebar,
  candidateId,
}: Props) => {
  const { activeAction } = useCandidateProfileContext()
  const [loading, setLoading] = useState(false)

  if (
    !isOpen ||
    !roundId ||
    !stage ||
    activeAction?.type !== 'onlineTestSummary' ||
    !candidateId
  ) {
    return null
  }

  const onSetResult = async (passed: boolean) => {
    setLoading(true)

    try {
      await setOnlineTestResult(roundId, stage.id, passed)
      onRefresh()
    } finally {
      setLoading(false)
    }
  }

  const title = stage.title
  const status = stage.scheduling_status_display
  const isCompleted = stage.scheduling_status === 'test_completed'

  return (
    <SideBar
      isOpen
      onClose={onClose}
      variant="wide"
      title={title}
      sideProps={{
        resizable: true,
      }}
      headerContent={
        <Header.Bar>
          <StageActions
            stage={stage}
            roundId={roundId}
            canCancel={false}
            canAddFeedback={false}
            currentStageId={stage.id}
            onRefresh={onRefresh}
            onOpenSidebar={onOpenSidebar}
            canViewEditOffer={false}
            menuType="primary"
            candidateId={candidateId}
          >
            {isCompleted && (
              <>
                <MoreBar.Action
                  useIcon="Check"
                  onClick={() => onSetResult(true)}
                  disabled={loading}
                >
                  Pass
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon="Cross"
                  variant="negative"
                  onClick={() => onSetResult(false)}
                  disabled={loading}
                >
                  Fail
                </MoreBar.Action>
              </>
            )}
          </StageActions>
        </Header.Bar>
      }
    >
      <VStack gap="s-16">
        <DetailsCell>
          <DetailsCell.Title>Status</DetailsCell.Title>
          <DetailsCell.Content>{status}</DetailsCell.Content>
        </DetailsCell>

        {isCompleted && stage.online_test_result ? (
          <OnlineTestResultWidget
            percentage={stage.online_test_result.percentage_score}
            score={stage.online_test_result.score}
            startTime={stage.online_test_result.started_date_time}
            endTime={stage.online_test_result.completed_date_time}
            plagiarism={stage.online_test_result.is_plagiarized}
            passed={stage.online_test_result.is_passed}
          />
        ) : (
          <FormPreview data={stage}>
            <Group>
              <FormPreview.Item<InterviewStageWithoutRoundInterface>
                title="Test link"
                insert={data =>
                  data.online_test?.test_url ? (
                    <CopyClipboardButton text={data.online_test?.test_url} />
                  ) : (
                    '-'
                  )
                }
              />
            </Group>
          </FormPreview>
        )}

        <FormPreview data={stage}>
          <Group>
            {isCompleted && (
              <>
                <FormPreview.Item<InterviewStageWithoutRoundInterface>
                  title="Link to full report"
                  insert={data =>
                    data.online_test_result?.report_url ? (
                      <Link href={data.online_test_result.report_url} target="_blank">
                        Open
                      </Link>
                    ) : (
                      '-'
                    )
                  }
                />
              </>
            )}
            <FormPreview.Item title="Name of test" field="online_test.name" />
            <FormPreview.Item
              title="Testing platform"
              field="test_platform"
              type="capitalized"
            />
            <FormPreview.Item
              title="Sent on"
              field="online_test_result.sent_date_time"
              type="dateTime"
            />
            <FormPreview.Item
              title="Start time"
              field="online_test_result.started_date_time"
              type="dateTime"
            />
            <FormPreview.Item
              title="End time"
              field="online_test_result.completed_date_time"
              type="dateTime"
            />
          </Group>
        </FormPreview>

        {stage.online_test_result?.test_report?.url && (
          <FormPreview title="Report">
            <iframe
              src={`${stage.online_test_result.test_report?.url}#view=fitH&toolbar=0&navpanes=0`}
              width="100%"
              height="780px"
              frameBorder="0"
              title="Document"
            />
          </FormPreview>
        )}
      </VStack>
    </SideBar>
  )
}
export default (props: Props) => (
  <ErrorBoundary>
    <OnlineTestSummarySidebar {...props} />
  </ErrorBoundary>
)
