import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { Box, chain, Item, Text, Token } from '@revolut/ui-kit'
import TextWithMoreCount from '@components/TextWithMoreCount/TextWithMoreCount'
import { formatDate } from '@src/utils/format'
import React, { useMemo } from 'react'

type Props = {
  item: InterviewRoundInterface
  activeRoundId?: number
  currentRound?: number
  onClick?: () => void
  archived?: boolean
  readOnly?: boolean
}

const ChangeRoundItem = ({
  item,
  activeRoundId,
  currentRound,
  onClick,
  archived,
  readOnly,
}: Props) => {
  // TODO: the BE should not return nullish items in the array it doesnt make sense
  const filteredApplicationForms = useMemo(() => {
    if (!item.application_forms) {
      return []
    }
    return item.application_forms.filter(form => !!form.job_posting)
  }, [item.application_forms])

  return (
    <Item
      key={item.id}
      use={readOnly ? undefined : 'button'}
      aria-pressed={currentRound === item.id}
      onClick={readOnly ? undefined : onClick}
      data-testid={`round-${item.id}`}
    >
      <Item.Content>
        <Item.Title>
          {chain(
            item.specialisation.name,
            activeRoundId === item.id && <Text color={Token.color.green}>Main</Text>,
            archived && <Text color={Token.color.red}>Archived</Text>,
          )}
        </Item.Title>
        <Item.Description>
          {item.recruiter && (
            <Box>
              <strong>Recruiter: </strong>
              <Text>{item.recruiter.display_name}</Text>
            </Box>
          )}
          {item.latest_interview_stage && (
            <Box>
              <strong>Stage: </strong>
              <Text>
                {chain(
                  item.latest_interview_stage.interview_type_display,
                  `${item.latest_interview_stage.interview_number}/${item.stage_count}`,
                )}
              </Text>
            </Box>
          )}
          {item.archived_reason && (
            <Box>
              <strong>Archival reason: </strong>
              <Text>{item.archived_reason.name}</Text>
            </Box>
          )}
          {!!filteredApplicationForms?.length && (
            <Box>
              <strong>Job postings: </strong>
              <TextWithMoreCount
                // TODO: remove "!" from "el.job_posting!.name" when BE is fixed
                items={filteredApplicationForms.map(el => el.job_posting!.name)}
                visibleCount={3}
                expandable
              />
            </Box>
          )}
          {item.latest_interview_stage_updated_date_time && (
            <Box>
              <strong>Stage updated: </strong>
              <Text>{formatDate(item.latest_interview_stage_updated_date_time)}</Text>
            </Box>
          )}
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

export default ChangeRoundItem
