import React from 'react'
import {
  ActionButton,
  Avatar,
  CounterWidget,
  CounterWidgetSkeleton,
  DetailsCell,
  DetailsCellSkeleton,
  Group,
  Item,
  ItemSkeleton,
  Separator,
  Text,
  Token,
} from '@revolut/ui-kit'
import {
  useGetJobPostingRequisitionStats,
  useGetJobPostingRequisitions,
} from '@src/api/requisitions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'

const RequisitionsOverview = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams()
  const { data: stats, isLoading: loadingStats } = useGetJobPostingRequisitionStats(
    values.id,
  )
  const { data: requisitionsData, isLoading: loadingRequisitions } =
    useGetJobPostingRequisitions(values.id)
  if (loadingStats || loadingRequisitions) {
    return (
      <Group>
        <ItemSkeleton />
        <CounterWidget>
          <CounterWidget.Column>
            <CounterWidgetSkeleton.Description />
            <CounterWidgetSkeleton.Subtitle />
          </CounterWidget.Column>
          <CounterWidget.Column>
            <CounterWidgetSkeleton.Description />
            <CounterWidgetSkeleton.Subtitle />
          </CounterWidget.Column>
        </CounterWidget>
        <Separator />
        <DetailsCellSkeleton />
        <DetailsCellSkeleton />
        <DetailsCellSkeleton />
      </Group>
    )
  }
  const totalHeadcount = stats?.requisition_total_headcount ?? 0
  const remainingHeadcount = stats?.requisition_remaining_headcount ?? 0
  const hiredHeadcount = totalHeadcount - remainingHeadcount
  const requisitions = requisitionsData?.results ?? []
  const openRequisitions = requisitions.filter(({ status }) => status === 'opened')
  const pendingRequisitions = requisitions.filter(({ status }) => status === 'pending')
  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Inbox" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Requisitions</Item.Title>
          <Item.Description>
            List of requisitions connected to this pipeline
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.JOB_POSTING.REQUISITIONS, params)}
          >
            View
          </ActionButton>
        </Item.Side>
      </Item>
      <CounterWidget>
        <CounterWidget.Column>
          <CounterWidget.Description>{totalHeadcount}</CounterWidget.Description>
          <CounterWidget.Subtitle textAlign="center">
            Total headcount
          </CounterWidget.Subtitle>
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidget.Description>{hiredHeadcount}</CounterWidget.Description>
          <CounterWidget.Subtitle textAlign="center">Hired</CounterWidget.Subtitle>
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidget.Description>{remainingHeadcount}</CounterWidget.Description>
          <CounterWidget.Subtitle textAlign="center">Remaining</CounterWidget.Subtitle>
        </CounterWidget.Column>
      </CounterWidget>
      <Separator />
      <DetailsCell variant="header">
        <DetailsCell.Title>Total Requisitions</DetailsCell.Title>
        <DetailsCell.Content>{requisitions.length}</DetailsCell.Content>
      </DetailsCell>
      <DetailsCell>
        <DetailsCell.Title>Open requisitions</DetailsCell.Title>
        <DetailsCell.Content>
          <Text color={Token.color.green}>{openRequisitions.length}</Text>
        </DetailsCell.Content>
      </DetailsCell>
      <DetailsCell>
        <DetailsCell.Title>Pending requisitions</DetailsCell.Title>
        <DetailsCell.Content>
          <Text color={Token.color.orange}>{pendingRequisitions.length}</Text>
        </DetailsCell.Content>
      </DetailsCell>
    </Group>
  )
}

export default RequisitionsOverview
