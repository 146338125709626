import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CandidateInterface,
  InterviewPendingSchedulingStatuses,
  InterviewPendingSchedulingTypes,
  InterviewRoundInterface,
  InterviewShowScheduleSidebarStatuses,
  InterviewType,
  ScheduleSidebarModeType,
  InterviewStageWithoutRoundInterface,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import UpcomingInterviewNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingInterviewNotifications'
import { Box } from '@revolut/ui-kit'
import { PermissionTypes } from '@src/store/auth/types'
import InterviewStartCard from '@src/pages/Forms/Candidate/InterviewProgress/InterviewStartCard'
import { InterviewFeedbackSidebar } from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/InterviewFeedbackSidebar'
import ArchiveDetails from '@src/pages/Forms/Candidate/InterviewProgress/components/ArchiveDetails'
import CandidateShortSummarySidebar from '@src/features/InterviewTool/CandidateShortSummarySidebar'
import { ROUTES } from '@src/constants/routes'
import UpcomingOnlineTestNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingOnlineTestNotifications'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import AnonymizeNotice from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/AnonymizeNotice'
import UpcomingOfferNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingOfferNotifications'
import ChangeRoundWidget from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/ChangeRoundWidget'
import ConfidentialCandidateWidget from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/ConfidentialCandidateWidget'
import PrepCallInterviewNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/PrepCallInterviewNotifications'
import { useGetOfferSettings } from '@src/api/settings'
import Comments from '@src/pages/Forms/Candidate/Comments/Comments'
import CVScreeningNotification from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/CVScreeningNotification'
import { useFetchInterviewStages } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import { SnoozeBanner } from '@src/pages/Forms/Candidate/InterviewProgress/components/Snooze'
import { StagesTable } from '@src/pages/Forms/Candidate/StagesTable/StagesTable'
import OnlineTestSummarySidebar from '@src/pages/Forms/Candidate/OnlineTestSummarySidebar/OnlineTestSummarySidebar'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import CandidateInformation from '@src/pages/Forms/Candidate/CandidateInformation'

interface Props {
  candidate?: CandidateInterface
  previewStageMode: boolean
  round?: InterviewRoundInterface
  refresh: () => Promise<void>
  refreshStats: () => void
  isLoading?: boolean
  canEditRound: boolean
  fetchRound: (roundId: number) => void
}

const Summary = ({
  candidate,
  round,
  previewStageMode,
  refresh,
  refreshStats,
  isLoading,
  canEditRound,
  fetchRound,
}: Props) => {
  const { setActiveAction, activeAction, setActiveStage, activeStage } =
    useCandidateProfileContext()
  const params = useParams<{ id: string }>()
  const { id } = params

  const [selectedStageItem, setSelectedStageItem] = useState<
    InterviewStageWithoutRoundInterface | InterviewFeedbackInterface
  >()
  const [selectedStageType, setSelectedStageType] = useState<InterviewType>()
  const currentStageId = candidate?.active_interview_round?.latest_interview_stage?.id
  const canViewEditOffer = candidate?.field_options?.permissions?.includes(
    PermissionTypes.ViewCandidateOffers,
  )

  const { data: offerSettings, isLoading: isLoadingOfferSettings } = useGetOfferSettings()
  const {
    data: stages,
    refetch: refetchStages,
    status: stagesLoadingStatus,
  } = useFetchInterviewStages(round?.id)

  useEffect(() => {
    const currentStage = stages?.find(item => item.id === currentStageId)
    setActiveStage(activeAction?.isPrepCall ? currentStage : selectedStageItem)
  }, [activeAction?.isPrepCall, currentStageId, selectedStageItem?.id])

  useEffect(() => {
    refetchStages()
  }, [candidate])

  const handleOpenSidebar = (
    data: InterviewStageWithoutRoundInterface | InterviewFeedbackInterface,
    mode?: ScheduleSidebarModeType,
  ) => {
    if ('scheduling_status' in data) {
      if (previewStageMode) {
        setActiveAction(undefined)
        setSelectedStageItem(data)
        return
      }

      if (data.interview_type === 'online_test') {
        setActiveAction({
          type: 'onlineTestSummary',
        })
      } else if (mode === 'rescheduling') {
        setActiveAction({
          type: 'schedule',
          mode: 'rescheduling',
        })
      } else if (
        InterviewPendingSchedulingStatuses.includes(data.scheduling_status) &&
        InterviewPendingSchedulingTypes.includes(data.interview_type)
      ) {
        setActiveAction({
          type: 'schedule',
          mode: 'scheduling',
        })
      } else if (InterviewShowScheduleSidebarStatuses.includes(data.scheduling_status)) {
        setActiveAction({
          type: 'schedule',
          mode: 'view',
        })
      } else {
        setActiveAction({
          type: 'shortSummary',
        })
      }

      setSelectedStageItem(data)
      return
    }

    setActiveAction({
      type: 'stage',
    })
  }

  const renderStagesTable = () => {
    return (
      <StagesTable
        round={round}
        onClick={(data, mode, stageType) => {
          setSelectedStageItem(data)
          setSelectedStageType(stageType)
          setActiveStage(data)

          if ('interview_type' in data && data.interview_type === 'offer') {
            return
          }
          handleOpenSidebar(data, mode)
        }}
        selectedItemId={selectedStageItem?.id}
        onRefresh={() => {
          refetchStages()
          refresh()
          setActiveAction(undefined)
          setSelectedStageItem(undefined)
        }}
        canViewEditOffer={!!canViewEditOffer}
        disableActions={previewStageMode}
        stages={stages}
        status={isLoading ? 'loading' : stagesLoadingStatus}
        candidateId={candidate?.id}
      />
    )
  }

  const renderComments = () => (
    <Comments
      roundId={round?.id}
      refreshStats={refreshStats}
      onClickSeeAll={() => navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.COMMENTS, params))}
    />
  )

  let canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )
  let canCancel = !!round?.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  // let canAnonymize = candidate?.field_options?.permissions?.includes(
  //   PermissionTypes.AnonymizeCandidate,
  // )
  //
  if (previewStageMode) {
    canAddFeedback = false
    canCancel = false
    // canAnonymize = false
  }
  //
  const canAddRound = previewStageMode
    ? false
    : !!round?.field_options?.permissions?.includes(PermissionTypes.AddInterviewRound)

  return (
    <>
      <>
        <TwoColumnsLayout
          left={
            previewStageMode ? (
              <>
                {round && candidate && (
                  <ChangeRoundWidget
                    candidateId={candidate.id}
                    round={round}
                    onRefresh={refresh}
                  />
                )}
                <ArchiveDetails round={round} />
                {renderStagesTable()}
                {renderComments()}
              </>
            ) : (
              <>
                {candidate?.is_confidential && (
                  <ConfidentialCandidateWidget candidate={candidate} />
                )}
                <SnoozeBanner candidate={candidate} onAfterSubmit={refresh} />
                <AnonymizeNotice date={candidate?.anonymising_expected_date_time} />
                {canViewEditOffer && round && !isLoadingOfferSettings && (
                  <UpcomingOfferNotifications
                    id={round.id}
                    signingEnabled={!!offerSettings?.enable_offer_signing}
                    onRefresh={refresh}
                  />
                )}
                <UpcomingInterviewNotifications id={id} />
                <UpcomingOnlineTestNotifications
                  candidateId={id}
                  round={candidate?.active_interview_round}
                />
                <PrepCallInterviewNotifications
                  roundId={round?.id}
                  onView={(interviewId, stageId) => {
                    setActiveAction({
                      type: 'schedule',
                      mode: 'view',
                      interviewId,
                      stageId,
                      isPrepCall: true,
                    })
                  }}
                />
                {candidate && (
                  <CVScreeningNotification
                    candidate={candidate}
                    round={candidate.active_interview_round}
                  />
                )}

                <ArchiveDetails round={round} />
                {candidate && !candidate.active_interview_round && (
                  <Box mt="s-16">
                    <InterviewStartCard candidate={candidate} />
                  </Box>
                )}
                {renderStagesTable()}
                {renderComments()}
              </>
            )
          }
          right={
            <CandidateInformation
              candidate={candidate}
              canAddRound={canAddRound}
              canEditRound={canEditRound}
              round={round}
              loading={isLoading}
              previewStageMode={previewStageMode}
              onOpenSidebar={type => {
                setActiveAction({ type })
              }}
              onPreviewRound={fetchRound}
            />
          }
        />
      </>

      <InterviewFeedbackSidebar
        isOpen={activeAction?.type === 'stage'}
        onExit={() => {
          setActiveAction(undefined)
          setSelectedStageItem(undefined)
        }}
        scorecard={activeStage as InterviewFeedbackInterface}
        title={(activeStage as InterviewFeedbackInterface)?.interviewer?.display_name}
        round={round}
        stageType={selectedStageType}
      />

      <CandidateShortSummarySidebar
        isOpen={Boolean(activeAction?.type === 'shortSummary' && activeStage)}
        onClose={() => setActiveAction(undefined)}
        stages={stages}
        stage={activeStage as InterviewStageWithoutRoundInterface | undefined}
        roundId={round?.id}
        onRefresh={() => {
          refresh()
          setActiveAction(undefined)
          setSelectedStageItem(undefined)
        }}
        canAddFeedback={canAddFeedback}
        canCancel={!!canCancel}
        onOpenSidebar={handleOpenSidebar}
        canViewEditOffer={!!canViewEditOffer}
        disableActions={previewStageMode}
        currentStageId={round?.latest_interview_stage?.id}
        candidateId={candidate?.id}
      />
      <OnlineTestSummarySidebar
        isOpen={Boolean(activeAction?.type === 'onlineTestSummary' && selectedStageItem)}
        onClose={() => setActiveAction(undefined)}
        stage={activeStage as InterviewStageWithoutRoundInterface | undefined}
        roundId={round?.id}
        onRefresh={() => {
          refetchStages()
          refreshStats()
          setActiveAction(undefined)
          setSelectedStageItem(undefined)
        }}
        onOpenSidebar={handleOpenSidebar}
        candidateId={candidate?.id}
      />
    </>
  )
}

export default Summary
