import React, { useEffect, useMemo, useState } from 'react'
import { Link as RouterLink, Route, Switch, useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { ColoredPercent } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { Dot } from '@src/pages/Forms/FormTitles'
import { useGetDepartment } from '@src/api/department'
import AuditCircles from '@components/ColumnInserts/AuditCircles/AuditCircles'
import RiskCircles from '@components/ColumnInserts/RiskCircles/RiskCircles'
import Tooltip from '@components/Tooltip/Tooltip'

import Requisitions from './Requisitions/Requisitions'
import Teams from './Teams/Teams'
import Roadmap from './Roadmap/Roadmap'
import Kpi from './KPI'
import Audit from './Findings/Findings'
import Risk from './Risk/Risk'
import Karma from './Karma/Karma'
import Talent from './Talent/Talent'
import CX from './CX'
import { AnalyticsDashboards } from './AnalyticsDashboards/AnalyticsDashboards'
import { TalentStatsInterface } from '@src/interfaces/functions'
import { getTalentStats } from '@src/api/talent'
import { Statuses } from '@src/interfaces'
import { ColoredText, Status } from '@components/CommonSC/General'
import CXCircles from '@src/components/ColumnInserts/CXCircles/CXCircles'
import { Box, Flex, Link, Token } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageWrapper } from '@components/Page/Page'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import upperFirst from 'lodash/upperFirst'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import CXBugs from '@src/features/FormTabs/CX/CXBugs'
import Bugs from '@src/features/FormTabs/CX/Bugs'
import { AuditCyclesInterface } from '@src/interfaces/audit'
import {
  useDepartmentBugCountsTickets,
  useDepartmentCXBugCountsTickets,
} from '@src/api/cx'
import Summary from '@src/pages/Forms/DepartmentForm/Summary/Summary'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import Budget, { SpentQuickSummary } from './Budget/Budget'
import Leadership from '@src/pages/Forms/DepartmentForm/Leadership/Leadership'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { chain } from '@revolut/ui-kit'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useGetDepartmentStaffCost } from '@src/api/staffCost'
import Distribution from './Budget/Distribution'
import { Engagement } from './Engagement/Engagement'
import Page404 from '@src/pages/Page404/Page404'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import { useSelector } from 'react-redux'
import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import {
  useGetOrganisationSettings,
  useGlobalSettings,
  useGetRoadmapSettings,
  useGetRequisitionSettings,
} from '@src/api/settings'
import { overallScoreToColor } from '@src/apps/People/Engagement/helpers'
import { MeetingsTracker } from '@src/pages/Forms/MeetingsTracker/MeetingsTracker'
import { MeetingTrackerEntity } from '@src/interfaces/meetingsTracker'
import { CXBugsReport } from '@src/features/FormTabs/CX/CXBugsReport'
import { BugsReport } from '@src/features/FormTabs/CX/BugsReport'
import { EntityTypes } from '@src/constants/api'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalsTab } from '@src/features/Goals/GoalsTab'

const Department = () => {
  const params = useParams<{ id: string; tab: string }>()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const user = useSelector(selectUser)

  const { data: settings } = useGetOrganisationSettings()
  const {
    settings: { compensation_enabled, engagement_enabled },
  } = useGlobalSettings()
  const cxEnabled = !!settings?.customer_experience?.enabled
  const bugTrackingEnabled = !!settings?.bugs_tracking?.enabled
  const findingsEnabled = !!settings?.findings?.enabled
  const riskEnabled = !!settings?.risk?.enabled
  const leadershipEnabled = !!settings?.enable_leadership
  const budgetManagementEnabled = !!settings?.enable_budget_management
  const karmaEnabled = !!settings?.karma?.enabled

  const { data, isLoading, refetch, isError, error } = useGetDepartment(params.id)
  const entity = useMemo<OrgEntityInterface | undefined>(
    () => (data ? { type: EntityTypes.department, data } : undefined),
    [data],
  )
  const [stats, setStats] = useState<TalentStatsInterface>()

  const bugCounts = useDepartmentBugCountsTickets(
    bugTrackingEnabled ? data?.id : undefined,
  )
  const CXBugCounts = useDepartmentCXBugCountsTickets(
    bugTrackingEnabled ? data?.id : undefined,
  )

  const canViewSpentTab = data?.field_options?.permissions?.includes(
    EntityPermissions.ViewSpentTab,
  )
  const canViewCompensationReviews = data?.field_options?.permissions?.includes(
    EntityPermissions.ViewCompensationReviews,
  )

  const { data: staffCost, isLoading: staffCostLoading } = useGetDepartmentStaffCost(
    canViewSpentTab && budgetManagementEnabled ? data?.id : undefined,
  )

  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: requisitionSettings } = useGetRequisitionSettings()

  const backUrl = ROUTES.ORGANISATION.DEPARTMENTS

  useEffect(() => {
    if (params.id) {
      getTalentStats('team__department__id', params.id).then(res => {
        if (res.data) {
          setStats(res.data)
        }
      })
    }
  }, [])

  if (isError) {
    return <FormErrorGuard error={error} />
  }

  if (isLoading || !data) {
    return <PageLoading />
  }

  if (!params.id) {
    return <Page404 />
  }

  const getDataBugsCircles = (): AuditCyclesInterface => {
    return {
      audits_exceptional: bugCounts.data?.bug_critical || 0,
      audits_critical: bugCounts.data?.bug_high || 0,
      audits_recommended: bugCounts.data?.bug_medium || 0,
      audits_trivial: bugCounts.data?.bug_low || 0,
    }
  }

  const getDataCXBugsCircles = (): AuditCyclesInterface => {
    return {
      audits_exceptional: CXBugCounts.data?.blocker || 0,
      audits_critical: CXBugCounts.data?.critical || 0,
      audits_recommended:
        (CXBugCounts.data?.major || 0) + (CXBugCounts.data?.medium || 0),
      audits_trivial:
        (CXBugCounts.data?.minor || 0) + (CXBugCounts.data?.improvement || 0),
    }
  }

  const isHod = user.id === data.owner?.id
  const canViewMeetingsTab =
    (isHod || permissions.includes(PermissionTypes.ViewMeeting)) &&
    featureFlags.includes(FeatureFlags.OneToOnes) &&
    !featureFlags.includes(FeatureFlags.CommercialProduct)

  const tabs = [
    {
      title: 'Summary',
      path: ROUTES.FORMS.DEPARTMENT.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, params),
      component: Summary,
      canView: !!data.id,
    },
    {
      path: ROUTES.FORMS.DEPARTMENT.LEADERSHIP,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.LEADERSHIP, params),
      title: 'Leadership',
      component: Leadership,
      canView: leadershipEnabled,
      quickSummary: chain(
        <QuickSummaryCount count={data.leadership_positions_count} />,
        <QuickSummaryCount
          count={data.unassigned_leadership_positions_count}
          color="red"
        />,
      ),
    },
    {
      title: 'Goals',
      quickSummary: Number.isFinite(data.goals_progress_percent) && (
        <ColoredPercent percent={data.goals_progress_percent! * 100} />
      ),
      path: ROUTES.FORMS.DEPARTMENT.GOALS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.GOALS, params),
      component: GoalsTab,
      canView: !!data.id && featureFlags.includes(FeatureFlags.CanAddGoals),
    },
    {
      title: 'KPI',
      quickSummary: <ColoredPercent percent={data.kpi_performance_percent * 100} />,
      path: ROUTES.FORMS.DEPARTMENT.KPI,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.KPI, params),
      component: Kpi,
      canView: !!data.id,
    },
    {
      title: 'Analytics',
      path: ROUTES.FORMS.DEPARTMENT.ANALYTICS_DASHBOARDS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ANALYTICS_DASHBOARDS, params),
      component: AnalyticsDashboards,
      canView: !!data.id && !featureFlags.includes(FeatureFlags.CommercialProduct),
      quickSummary: <QuickSummaryCount count={data.dashboard_count} />,
    },
    {
      title: 'Roadmap',
      quickSummary: <ColoredPercent percent={data.roadmap_progress_percent * 100} />,
      path: ROUTES.FORMS.DEPARTMENT.ROADMAP,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, params),
      component: Roadmap,
      canView: !!data.id && !!roadmapSettings?.enabled,
    },
    {
      title: 'Teams',
      quickSummary: <QuickSummaryCount count={data.teams_count} />,
      path: ROUTES.FORMS.DEPARTMENT.STRUCTURE,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.STRUCTURE, params),
      component: Teams,
      canView: !!data.id,
    },
    {
      title: 'Talent',
      path: ROUTES.FORMS.DEPARTMENT.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.TALENT.GENERAL, params),
      quickSummary: getTalentStatsQuickSummary({
        headcount: data?.headcount,
        nips: stats?.nips,
      }),
      component: Talent,
      canView: !!data && !!data.id,
    },
    {
      title: 'Meetings' as const,
      path: ROUTES.FORMS.DEPARTMENT.MEETINGS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.MEETINGS, params),
      component: MeetingsTracker,
      canView: !!data.id && canViewMeetingsTab,
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.DEPARTMENT.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.REQUISITIONS, params),
      quickSummary: <QuickSummaryCount count={data.requisition_remaining_headcount} />,
      component: Requisitions,
      canView: !!data.id && requisitionSettings?.enabled,
    },
    {
      title: 'CX',
      quickSummary: <CXCircles data={data} />,
      path: ROUTES.FORMS.DEPARTMENT.CX.ANY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.CX.ALL, params),
      component: CX,
      canView: cxEnabled && !!data.id,
    },
    {
      path: ROUTES.FORMS.DEPARTMENT.CX_BUGS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.CX_BUGS, params),
      title: 'Product Audits',
      component: CXBugs,
      canView: bugTrackingEnabled,
      quickSummary: <AuditCircles data={getDataCXBugsCircles()} />,
    },
    // tab with Table UI Report Result
    {
      title: 'Product Audits New',
      path: ROUTES.FORMS.DEPARTMENT.CX_BUGS_V2,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.CX_BUGS_V2, params),
      component: CXBugsReport,
      canView: bugTrackingEnabled && featureFlags.includes(FeatureFlags.ReportingApp),
    },
    {
      path: ROUTES.FORMS.DEPARTMENT.BUGS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.BUGS, params),
      title: 'Technical Bugs',
      canView: bugTrackingEnabled,
      component: Bugs,
      quickSummary: <AuditCircles data={getDataBugsCircles()} />,
    },
    // tab with Table UI Report Result
    {
      path: ROUTES.FORMS.DEPARTMENT.BUGS_V2,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.BUGS_V2, params),
      title: 'Technical Bugs New',
      canView: bugTrackingEnabled && featureFlags.includes(FeatureFlags.ReportingApp),
      component: BugsReport,
    },
    {
      title: 'Findings',
      quickSummary: <AuditCircles data={data} />,
      path: ROUTES.FORMS.DEPARTMENT.AUDIT,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.AUDIT, params),
      component: Audit,
      canView: findingsEnabled && !!data.id,
    },
    {
      title: 'Engagement',
      path: ROUTES.FORMS.DEPARTMENT.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ENGAGEMENT.DRIVERS, params),
      component: Engagement,
      canView:
        engagement_enabled &&
        !!data.id &&
        permissions.includes(PermissionTypes.ViewEngagementTabs),
      quickSummary: (
        <QuickSummaryCount
          show0InGrey
          count={data.average_score}
          color={overallScoreToColor(data.average_score)}
        />
      ),
    },
    {
      title: 'Risk',
      quickSummary: <RiskCircles data={data} />,
      path: ROUTES.FORMS.DEPARTMENT.RISK.ANY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.SUMMARY, params),
      component: Risk,
      canView:
        riskEnabled &&
        !!data?.id &&
        data.field_options.actions?.includes(EntityPermissions.ViewKri),
    },
    {
      title: 'Karma',
      path: ROUTES.FORMS.DEPARTMENT.KARMA,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.KARMA, params),
      quickSummary: <QuickSummaryCount count={data.karma} />,
      canView: karmaEnabled && !!data?.id,
      component: Karma,
    },
    {
      title: 'Budget',
      path: ROUTES.FORMS.DEPARTMENT.BUDGET.SPENT,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.BUDGET.SPENT, params),
      quickSummary: (
        <SpentQuickSummary staffCost={staffCost} isLoading={staffCostLoading} />
      ),
      canView: budgetManagementEnabled && (canViewSpentTab || canViewCompensationReviews),
      component: Budget,
    },
    {
      title: 'Compensation',
      path: ROUTES.FORMS.DEPARTMENT.BUDGET.DISTRIBUTION,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.BUDGET.DISTRIBUTION, params),
      canView:
        compensation_enabled && budgetManagementEnabled && canViewCompensationReviews,
      component: Distribution,
    },
  ]

  const filteredTabs = tabs.filter(organisationSubtab => {
    if (organisationSubtab.canView === undefined) {
      return true
    }

    return organisationSubtab.canView
  })

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <PageHeader
          title={
            <>
              {data.name || 'New Department'}
              {!!data.performance && (
                <>
                  <Dot>·</Dot>
                  <Box display="inline-block">
                    <Tooltip placement="right" text="Overall Performance">
                      <ColoredPercent percent={data.performance * 100} />
                    </Tooltip>
                  </Box>
                </>
              )}
              {data.status === Statuses.archived && (
                <>
                  <Dot>·</Dot>
                  <Box display="inline-block">
                    <Tooltip placement="right" text="Overall Performance">
                      <ColoredText color={Token.color.greyTone20}>Archived</ColoredText>
                    </Tooltip>
                  </Box>
                </>
              )}
              {data.approval_status !== ApprovalStatuses.Approved && (
                <>
                  <Dot>·</Dot>
                  <Status status={data.approval_status}>
                    {upperFirst(data.approval_status)}
                  </Status>
                </>
              )}
            </>
          }
          subtitle={
            data.owner ? (
              <Link
                color="inherit"
                use={RouterLink}
                to={getLocationDescriptor(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: data.owner.id,
                  }),
                )}
              >
                Department owner: {data.owner.name}
              </Link>
            ) : undefined
          }
          backUrl={backUrl}
        >
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={filteredTabs} />
          </Box>
        </PageHeader>
        <Flex pt="s-8" flex="1 0" flexDirection="column" width="100%" minHeight={0}>
          <Switch>
            {filteredTabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component
                  data={data!}
                  refetch={refetch}
                  meetingEntityType={MeetingTrackerEntity.department}
                  entity={EntityTypes.department}
                />
              </Route>
            ))}
          </Switch>
        </Flex>
      </PageWrapper>
    </OrgEntityProvider>
  )
}

export default Department
