import React, { useMemo, useRef, useState } from 'react'
import { connect } from 'lape'
import {
  Avatar,
  Box,
  Color,
  Flex,
  Group,
  Icon,
  IconButton,
  Item,
  Link,
  Spacer,
  Spinner,
  Text,
  Token,
  Tooltip,
  TransitionFade,
  VStack,
  Widget,
  useTooltip,
} from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import { useGetJobDescription } from '@src/api/jobPosting'
import styled, { css } from 'styled-components'
import { getNormalizedLocations } from '@src/pages/Forms/JobPosting/utils'
import { JobPostingLocationInterface } from '@src/interfaces/jobPosting'
import { pushNotification } from '@src/store/notifications/actions'
import {
  SUCCESS_DEFAULT_DURATION,
  ERROR_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { SidebarJobDescription } from '@src/interfaces/jobDescription'
import JobPreviewActionsButton from './JobPreviewActionsButton'

const copyHTMLContent = async (element: HTMLElement) => {
  try {
    if (element.textContent) {
      const htmlBlob = new Blob([element.outerHTML], { type: 'text/html' })
      const plainBlob = new Blob([element.textContent], { type: 'text/plain' })
      const clipboardItem = new ClipboardItem({
        'text/html': htmlBlob,
        'text/plain': plainBlob,
      })
      await navigator.clipboard.write([clipboardItem])
      pushNotification({
        value: 'Copied to clipboard',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    }
  } catch {
    pushNotification({
      value: 'Failed Copying to clipboard',
      duration: ERROR_DEFAULT_DURATION,
      type: NotificationTypes.error,
    })
  }
}

interface JobDescriptionProps {
  id?: number
  locations?: JobPostingLocationInterface[]
}

const sectionCss = css`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${Token.color.foreground};
  }

  p {
    margin: 16px 0;
  }

  ol,
  ul {
    padding: 0 0 0 20px;
    margin: 0;
  }
`

const CopyBreak = styled.br`
  display: none;
`

type LocationsProps = {
  icon: React.ReactNode
  locations: string[]
  title: string
}

const Locations = ({ icon, locations, title }: LocationsProps) => {
  return (
    <Box>
      <Box
        use="span"
        display="inline-block"
        style={{
          verticalAlign: 'text-top',
        }}
        mr="s-8"
      >
        {icon}
      </Box>
      <Text use="b" variant="caption">
        {title}:{' '}
      </Text>
      <Text variant="caption" color={Token.color.greyTone50}>
        {locations.join(' | ')}
      </Text>
    </Box>
  )
}

type SectionProps = {
  fontFamily: string
  section: SidebarJobDescription
}

const Section = ({ fontFamily, section }: SectionProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const tooltip = useTooltip()
  const [displayCopy, setDisplayCopy] = useState(false)
  const isClosing = (title?: string) => title === 'Closing'
  const link = section?.section_url || section.section?.section_url
  const linkTitle = section?.section_url_title || section.section?.section_url_title
  return (
    <Box
      mb="s-24"
      ref={contentRef}
      style={{
        fontFamily,
      }}
      onMouseEnter={() => {
        setDisplayCopy(true)
      }}
      onMouseLeave={() => {
        setDisplayCopy(false)
      }}
    >
      <Flex justifyContent="space-between">
        {section.title && !isClosing(section.title) && (
          <Text use="b" variant="h5" mb="s-16">
            {section.title}
          </Text>
        )}
        <Spacer height="s-24" />
        <TransitionFade in={displayCopy}>
          <Box>
            <IconButton
              color={Color.BLUE}
              onClick={() => {
                if (contentRef.current) {
                  copyHTMLContent(contentRef.current)
                }
              }}
              useIcon="Copy"
              {...tooltip.getAnchorProps()}
            />
            <Tooltip {...tooltip.getTargetProps()}>Copy section content</Tooltip>
          </Box>
        </TransitionFade>
      </Flex>
      <Text
        css={sectionCss}
        use="pre"
        style={{
          fontFamily,
          textAlign: 'justify',
          whiteSpace: 'pre-wrap',
        }}
        variant="caption"
        color={isClosing(section.title) ? Token.color.foreground : Token.color.greyTone50}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(section.content),
        }}
      />
      {!!link && (
        <Link href={link} target="_blank" rel="noopener noreferrer">
          {linkTitle || 'See more'}
        </Link>
      )}
      <CopyBreak />
    </Box>
  )
}

const JobPosting = ({ locations, id }: JobDescriptionProps) => {
  const { data, isLoading } = useGetJobDescription(id)
  const contentRef = useRef<HTMLDivElement>(null)

  const fontFamily = useMemo(() => {
    // this is to get the actual value of Token.font.brand
    // we need the actual value for copy functionality
    return window.getComputedStyle(document.body).getPropertyValue('--rui-font-brand')
  }, [])

  if (isLoading) {
    return (
      <Widget p="s-16">
        <Spinner />
      </Widget>
    )
  }

  const loc = getNormalizedLocations(locations || data?.locations || [])

  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Newspaper" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Preview</Item.Title>
        </Item.Content>
        <Item.Side>
          <JobPreviewActionsButton
            onCopyJobPosting={() => {
              if (contentRef.current) {
                copyHTMLContent(contentRef.current)
              }
            }}
          />
        </Item.Side>
      </Item>
      <Widget py="s-24" px="s-16" data-testid="job-description">
        <Box
          ref={contentRef}
          style={{
            fontFamily,
          }}
        >
          <Text variant="display3">{data?.name || '<Role name>'}</Text>
          <VStack mt="s-24" gap="s-8" color={Color.GREY_TONE_50}>
            {!!loc.office.length && (
              <Text variant="caption">
                <Locations
                  icon={<Icon name="Bank" size={16} />}
                  locations={loc.office}
                  title="Office"
                />
              </Text>
            )}
            {!!loc.remote.length && (
              <Text variant="caption">
                <Locations
                  icon={<Icon name="Laptop" size={16} />}
                  locations={loc.remote}
                  title="Remote"
                />
              </Text>
            )}
            {!loc.remote.length && !loc.office.length && (
              <Text variant="caption">{`<Location will be displayed here>`}</Text>
            )}
          </VStack>
          <CopyBreak />
          <Box pt="s-40">
            {data?.sections?.map(section => (
              <Section key={section.id} section={section} fontFamily={fontFamily} />
            ))}
          </Box>
        </Box>
      </Widget>
    </Group>
  )
}

export default connect(JobPosting)
