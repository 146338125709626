import React from 'react'
import { ErrorWidget } from '@revolut/ui-kit'
import CandidateDataSkeleton from '@src/pages/Forms/Candidate/InterviewProgress/components/CandidateDataSkeleton'
import CandidateData from '@src/features/InterviewTool/CandidateData'
import {
  CandidateInterface,
  CandidateSidebarTypes,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import ChangeRound from './InterviewProgress/components/ChangeRound/ChangeRound'

type CandidateInformationProps = {
  candidate?: CandidateInterface
  canAddRound: boolean
  canEditRound: boolean
  round?: InterviewRoundInterface
  loading?: boolean
  previewStageMode: boolean
  onOpenSidebar: (type: CandidateSidebarTypes) => void
  onPreviewRound: (roundId: number) => void
}

const CandidateInformation = ({
  candidate,
  canAddRound,
  canEditRound,
  round,
  loading,
  previewStageMode,
  onOpenSidebar,
  onPreviewRound,
}: CandidateInformationProps) => {
  const previewPermissions = {
    canAddRound: false,
    canEditRound: false,
    canEditCandidateSidebar: false,
    canEditPersonalDetails: false,
    canOpenAppliedJobs: false,
    canViewAppliedJobs: false,
    canViewCandidate: true,
  }
  const defaultPermissions = {
    canAddRound,
    canEditRound,
    canEditCandidateSidebar: true,
    canEditPersonalDetails: true,
    canOpenAppliedJobs: false,
    canViewAppliedJobs: false,
    canViewCandidate: true,
  }
  const permissions = previewStageMode ? previewPermissions : defaultPermissions
  return (
    <>
      {loading ? (
        <CandidateDataSkeleton />
      ) : (
        <>
          {candidate ? (
            <CandidateData
              candidate={candidate}
              interviewingFor={false}
              hideCandidate
              opportunityId={round?.opportunity_id}
              permissions={permissions}
              round={round}
              onOpenSidebar={onOpenSidebar}
              onShowCV={() => {
                onOpenSidebar('cv')
              }}
            />
          ) : (
            <ErrorWidget>
              <ErrorWidget.Image />
              <ErrorWidget.Title>Something went wrong</ErrorWidget.Title>
              <ErrorWidget.Description>
                Missing candidate information
              </ErrorWidget.Description>
            </ErrorWidget>
          )}
        </>
      )}
      {candidate && (
        <ChangeRound
          onPreviewRound={onPreviewRound}
          candidateId={candidate.id}
          activeRoundId={candidate.active_interview_round?.id}
        />
      )}
    </>
  )
}

export default CandidateInformation
