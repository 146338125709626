import React from 'react'
import { Tooltip, useTooltip } from '@revolut/ui-kit'
import { Pencil } from '@revolut/icons'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import SettingsButton, {
  SettingsButtonProps,
} from '@src/features/SettingsButtons/SettingsButton'

interface EditButtonProps extends SettingsButtonProps {
  route: string
  isVisible?: boolean
  tooltip?: React.ReactNode
  disabled?: boolean
}

export const EditButton = ({
  useMoreButton,
  route,
  isVisible = true,
  tooltip,
  disabled,
}: EditButtonProps) => {
  const tooltipProps = useTooltip()

  if (!isVisible) {
    return null
  }

  return (
    <SettingsButton
      useMoreButton={useMoreButton}
      to={disabled ? undefined : route}
      useIcon={Pencil}
      use={disabled ? 'div' : InternalLink}
      disabled={disabled}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
      {...tooltipProps.getAnchorProps()}
    >
      Edit
      {tooltip ? (
        <Tooltip {...tooltipProps.getTargetProps()} maxWidth={200}>
          {tooltip}
        </Tooltip>
      ) : null}
    </SettingsButton>
  )
}
