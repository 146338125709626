import React from 'react'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import {
  Avatar,
  Box,
  Cell,
  Icon,
  Input,
  Item,
  Subheader,
  Token,
  VStack,
  List,
  HStack,
  Flex,
  MoreButton,
} from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsTemplateMoneyFieldInterface,
  DocumentsTemplatesInterface,
} from '@src/interfaces/documentsTemplates'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import NewDatePicker from '@components/Inputs/NewDatePicker/NewDatePicker'
import { selectorKeys } from '@src/constants/api'

import {
  TemplateField,
  getFieldsByPage,
  getIndexedFieldKey,
  getSourceIdToLabel,
  getSourceOptions,
  hasFieldsOnPage,
  mapTypeToAvatar,
  recipientOptions,
  hasAnyFields,
  ErrorsByFieldsKeys,
  FieldType,
  fieldTypes,
  IndexedFieldKey,
  FieldActionsInterface,
  getFieldName,
  getFieldInfoFromIndexedKey,
} from './common'

type FieldSettingsProps = React.PropsWithChildren<
  TemplateField & { hasInnerFieldsErrors: boolean }
>

const FieldSettings = ({
  id,
  data,
  isSelected,
  setSelected,
  setUnselected,
  onDelete,
  onRename,
  children,
  errors,
  clearError,
  hasInnerFieldsErrors,
}: FieldSettingsProps) => {
  const { type, idx } = getFieldInfoFromIndexedKey(id)

  const hasErrors = !isEmpty(errors) || hasInnerFieldsErrors
  const signerRoleError = errors?.signer_role?.[0]
  const sqlSourceError = errors?.sql_source?.[0]
  const nonFieldErrors = Object.values(omit(errors, 'signer_role', 'sql_source')).flat()

  return (
    <Flex alignItems="flex-start" data-testid={`${id}_settings-section`}>
      <VStack width="100%" space="s-4">
        <Item
          use="button"
          type="button"
          onClick={isSelected ? setUnselected : setSelected}
          aria-pressed={isSelected}
        >
          <Item.Avatar>
            {hasErrors ? (
              <Avatar useIcon="ExclamationTriangle" color={Token.color.error} />
            ) : (
              mapTypeToAvatar[type]
            )}
          </Item.Avatar>
          <Item.Content>
            <Item.Title>{getFieldName(data, type, idx)} settings</Item.Title>
          </Item.Content>
          <Item.Side>
            <HStack align="center" space="s-8">
              <Icon name={isSelected ? 'ChevronUp' : 'ChevronDown'} />
              <MoreButton
                variant="icon"
                color={Token.color.greyTone50}
                onClick={e => {
                  e.stopPropagation()
                }}
                aria-label={`${data.name} field actions`}
              >
                <MoreButton.Action
                  useIcon="Pencil"
                  onClick={e => {
                    e.stopPropagation()
                    onRename?.()
                  }}
                  aria-label={`Edit ${data.name} field name`}
                >
                  Rename
                </MoreButton.Action>
                <MoreButton.Action
                  useIcon="Delete"
                  color={Token.color.red}
                  onClick={e => {
                    e.stopPropagation()
                    onDelete?.()
                  }}
                  aria-label={`Delete ${data.name} field`}
                >
                  Delete
                </MoreButton.Action>
              </MoreButton>
            </HStack>
          </Item.Side>
        </Item>
        {isSelected && (
          <>
            {!!nonFieldErrors?.length && (
              <Box mx="s-8" p="s-8" pb="s-16">
                <List variant="compact">
                  {nonFieldErrors.map(errMessage => (
                    <List.Item
                      key={errMessage}
                      useIcon="ExclamationMarkOutline"
                      color={Token.color.error}
                    >
                      {errMessage}
                    </List.Item>
                  ))}
                </List>
              </Box>
            )}
            <VStack p="s-4" pt={0} space="s-4">
              {type !== 'signature' && (
                <RadioSelectInput
                  label="Data source"
                  options={getSourceOptions(type)}
                  value={data.source_type}
                  onChange={newValue => {
                    if (newValue) {
                      data.source_type = newValue
                      data.custom_value = null
                    }
                  }}
                  searchable={false}
                />
              )}
              {data.source_type.id === 'to_be_filled' && (
                <>
                  <Input
                    label="Placeholder"
                    value={data.placeholder}
                    onChange={e => {
                      data.placeholder = e.currentTarget.value
                    }}
                  />
                  <RadioSelectInput
                    label="Signer"
                    options={recipientOptions}
                    value={data.signer_role}
                    onChange={newValue => {
                      if (newValue) {
                        clearError?.(id, 'signer_role')
                        data.signer_role = newValue
                      }
                    }}
                    searchable={false}
                    hasError={!!signerRoleError}
                    message={signerRoleError}
                  />
                </>
              )}
              {data.source_type.id === 'sql_source' && (
                <>
                  <RadioSelectInput
                    label="SQL source"
                    selector={selectorKeys.document_esignature_sql_sources}
                    value={data.sql_source}
                    onChange={newValue => {
                      if (newValue) {
                        clearError?.(id, 'sql_source')
                        data.sql_source = newValue
                      }
                    }}
                    hasError={!!sqlSourceError}
                    message={sqlSourceError}
                  />
                </>
              )}
              {children}
            </VStack>
          </>
        )}
      </VStack>
    </Flex>
  )
}

type TypedFieldSettingsProps = Omit<FieldSettingsProps, 'type'>

const TextFieldSettings = (props: TypedFieldSettingsProps) => {
  const { id, data, errors, clearError } = props

  const customValueError = errors?.custom_value?.[0]
  const hasInnerFieldsErrors = Boolean(customValueError)
  const outerErrors = omit(errors, 'custom_value')

  return (
    <FieldSettings
      {...props}
      errors={outerErrors}
      hasInnerFieldsErrors={hasInnerFieldsErrors}
    >
      {data.source_type.id === 'custom_value' && (
        <Input
          label={getSourceIdToLabel('text', false).custom_value}
          value={data.custom_value || undefined}
          onChange={e => {
            clearError?.(id, 'custom_value')
            data.custom_value = e.currentTarget.value
          }}
          aria-invalid={!!customValueError}
          errorMessage={customValueError}
        />
      )}
    </FieldSettings>
  )
}

const NumberFieldSettings = (props: TypedFieldSettingsProps) => {
  const { id, data, errors, clearError } = props

  const customValueError = errors?.custom_value?.[0]
  const hasInnerFieldsErrors = Boolean(customValueError)
  const outerErrors = omit(errors, 'custom_value')

  return (
    <FieldSettings
      {...props}
      errors={outerErrors}
      hasInnerFieldsErrors={hasInnerFieldsErrors}
    >
      {data.source_type.id === 'custom_value' && (
        <Input
          type="number"
          label={getSourceIdToLabel('number', false).custom_value}
          value={data.custom_value || undefined}
          onChange={e => {
            clearError?.(id, 'custom_value')
            data.custom_value = e.currentTarget.value
          }}
          aria-invalid={!!customValueError}
          errorMessage={customValueError}
        />
      )}
    </FieldSettings>
  )
}

const DateFieldSettings = (props: TypedFieldSettingsProps) => {
  const { id, data, errors, clearError } = props

  const customValueError = errors?.custom_value?.[0]
  const hasInnerFieldsErrors = Boolean(customValueError)
  const outerErrors = omit(errors, 'custom_value')

  return (
    <FieldSettings
      {...props}
      errors={outerErrors}
      hasInnerFieldsErrors={hasInnerFieldsErrors}
    >
      {data.source_type.id === 'custom_value' && (
        <NewDatePicker
          label={getSourceIdToLabel('date', false).custom_value}
          value={data.custom_value as string}
          onChange={newDate => {
            if (newDate) {
              clearError?.(id, 'custom_value')
              data.custom_value = newDate.toISOString()
            }
          }}
          hasError={!!customValueError}
          error={customValueError}
        />
      )}
    </FieldSettings>
  )
}

const MoneyFieldSettings = (props: TypedFieldSettingsProps) => {
  const data = props.data as DocumentsTemplateMoneyFieldInterface

  const { id, errors, clearError } = props
  const customValueError = errors?.custom_value?.[0]
  const currencyError = errors?.currency?.[0]
  const hasInnerFieldsErrors = Boolean(customValueError || currencyError)
  const outerErrors = omit(errors, ['custom_value', 'currency'])

  return (
    <FieldSettings
      {...props}
      errors={outerErrors}
      hasInnerFieldsErrors={hasInnerFieldsErrors}
    >
      {data.source_type.id === 'custom_value' && (
        <>
          <Input
            type="money"
            label={getSourceIdToLabel('money', false).custom_value}
            value={data.custom_value}
            onChange={newValue => {
              if (newValue != null) {
                clearError?.(id, 'custom_value')
                data.custom_value = newValue
              }
            }}
            aria-invalid={!!customValueError}
            errorMessage={customValueError}
          />
          <RadioSelectInput
            label="Currency"
            value={data.currency}
            selector={selectorKeys.currencies}
            onChange={newValue => {
              if (newValue) {
                clearError?.(id, 'currency')
                data.currency = newValue
              }
            }}
            hasError={!!currencyError}
            message={currencyError}
          />
        </>
      )}
    </FieldSettings>
  )
}

const SignatureFieldSettings = (props: TypedFieldSettingsProps) => {
  return <FieldSettings {...props} />
}

type Props = {
  totalPages?: number
  selectedFieldKey: IndexedFieldKey | undefined
  setSelectedFieldKey: (id: IndexedFieldKey | undefined) => void
  handleDelete: (id: IndexedFieldKey) => void
  handleRename: (id: IndexedFieldKey) => void
  errorsByFieldsKeys: ErrorsByFieldsKeys
  clearError: (indexedKey: IndexedFieldKey, errorField: string) => void
}
export const FieldsSettingsBar = ({
  totalPages,
  selectedFieldKey,
  setSelectedFieldKey,
  handleDelete,
  handleRename,
  errorsByFieldsKeys,
  clearError,
}: Props) => {
  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  const getActionsProps = (key: string): FieldActionsInterface => ({
    isSelected: key === selectedFieldKey,
    setSelected: () => setSelectedFieldKey(key),
    setUnselected: () => setSelectedFieldKey(undefined),
    onDelete: () => handleDelete(key),
    onRename: () => handleRename(key),
  })

  if (!hasAnyFields(values)) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar useIcon="ExclamationTriangle" color={Token.color.blue} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>No any fields have been added yet</Item.Title>
          <Item.Description>Click "Add data field" to create a new one</Item.Description>
        </Item.Content>
      </Item>
    )
  }

  return (
    <VStack maxHeight="calc(100vh - 100px)" overflow="scroll" pr="s-4">
      {[...Array(totalPages).keys()].map(pageIdx => {
        const pageNum = pageIdx + 1

        if (!hasFieldsOnPage(pageNum, values)) {
          return null
        }
        return (
          <Box key={pageIdx}>
            <Subheader variant="nested">
              <Subheader.Title>Page {pageNum}</Subheader.Title>
            </Subheader>
            <Cell p="s-4">
              <VStack space="s-8" width="100%" overflow="scroll">
                {fieldTypes.map(fieldType => {
                  const settingsComponentByType: Record<FieldType, React.ElementType> = {
                    text: TextFieldSettings,
                    number: NumberFieldSettings,
                    date: DateFieldSettings,
                    money: MoneyFieldSettings,
                    signature: SignatureFieldSettings,
                  }

                  return getFieldsByPage(fieldType, pageNum, values).map((field, idx) => {
                    const key = getIndexedFieldKey(fieldType, pageIdx, idx)
                    const SettingsComponent = settingsComponentByType[fieldType]
                    return (
                      <SettingsComponent
                        id={key}
                        key={key}
                        data={field}
                        errors={errorsByFieldsKeys[key]}
                        clearError={clearError}
                        {...getActionsProps(key)}
                      />
                    )
                  })
                })}
              </VStack>
            </Cell>
          </Box>
        )
      })}
    </VStack>
  )
}
