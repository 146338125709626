import { api, apiWithoutHandling } from '@src/api'
import {
  GetRequestData,
  RequestInterfaceNew,
  Statuses,
  TableRequestInterface,
} from '@src/interfaces'
import {
  GoalsInterface,
  GoalsTimelineEventInterface,
  GoalWeightMode,
  ManageGoalFields,
  ParentGoalSelector,
} from '@src/interfaces/goals'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { getCommentsAPI } from './comments'

// comment field used only to provide justification for some updates
export const goalsRequests: RequestInterfaceNew<GoalsInterface & { comment?: string }> = {
  get: async ({ id }) => {
    return api.get(`${API.GOALS}/${id}`)
  },
  update: async (data, { id }) => apiWithoutHandling.patch(`${API.GOALS}/${id}`, data),
  delete: async ({ id }) => api.delete(`${API.GOALS}/${id}`),
  submit: async data => apiWithoutHandling.post(API.GOALS, data),
}

export type GoalsInterfaceWithChildren = GoalsInterface & {
  children?: GoalsInterface['kpis']
}

export type GoalsStats = { overall_progress: number }

export const goalsListTableRequests: TableRequestInterface<
  GoalsInterfaceWithChildren,
  GoalsStats
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api
      .get<GetRequestData<GoalsInterface>>(
        `${API.GOALS}`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        'v1',
      )
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(row => ({ ...row, children: row.kpis })),
        },
      })),
  getStats: async ({ filters, page }) =>
    apiWithoutHandling.get(
      `${API.GOALS}/stats`,
      {
        params: filterSortPageIntoQuery(undefined, filters, page),
      },
      'v1',
    ),
}

export const goalsTimelineTableRequests = (
  id: number,
): TableRequestInterface<GoalsTimelineEventInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.GOALS}/${id}/timeline`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
})

export const useGetGoalParentSelector = (
  contentType?: 'employee_goal' | 'team_goal' | 'department_goal',
) => {
  return useFetch<ParentGoalSelector[]>(
    contentType ? `${API.GOALS}/parentSelector?type=${contentType}` : null,
  )
}

export const useGetGoal = (id?: number) => {
  return useFetch<GoalsInterface>(id ? `${API.GOALS}/${id}` : null)
}

// todo: proper implementation
export const fetchGoalGraph = async () =>
  ({
    statues: 200,
    data: { targets: [], progress_history: [] },
  } as unknown as AxiosPromise<PerformanceChartCycles>)

export const approveGoal = (id: number) => {
  return apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/approve`)
}

export const blukApproveGoals = (goals: Array<{ id: GoalsInterface['id'] }>) => {
  return apiWithoutHandling.post<never>(`${API.GOALS}/bulkApprove`, { goals })
}

export const requestChange = (id: number, reason: string) => {
  return apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/reject`, {
    comment: reason,
  })
}

export const useGetGoalApprovalFlow = (id: number | undefined) => {
  return useFetch<ApprovalFlowResponse>(id ? `${API.GOALS}/${id}/approvals` : null)
}

export const useGetGoals = (query?: string) =>
  useFetch<GetRequestData<GoalsInterface>>(query ? `${API.GOALS}${query}` : null)

export const calibrateGoal = (
  id: number,
  data: { calibrated_progress: number; status: Statuses; comment: string },
) => apiWithoutHandling.patch(`${API.GOALS}/${id}`, data)

export type ManageGoalEntityDefinition =
  | {
      content_type: { id: number }
      object_id: number
    }
  | {
      is_company: true
    }

export type ManageGoalsPayload = {
  goal_weight_mode: { id: GoalWeightMode }
  goals: ManageGoalFields[]
} & ManageGoalEntityDefinition

export const manageGoals = (payload: ManageGoalsPayload) =>
  apiWithoutHandling.post(`${API.GOALS}/manageGoals`, payload)

export const refreshGoalProgress = (id: number) =>
  apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/refresh`)

export const archiveGoal = (id: number) =>
  apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/archive`)

export const unarchiveGoal = (id: number) =>
  apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/unarchive`)

export const deleteGoal = (id: number) =>
  apiWithoutHandling.delete<GoalsInterface>(`${API.GOALS}/${id}`)

export const getGoalCommentsAPI = (id: number) =>
  getCommentsAPI({ baseUrl: `${API.GOALS}/${id}/comments` })
