import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { connect } from 'lape'
import { navigateReplace } from '@src/actions/RouterActions'
import {
  createRelatedCompany,
  createRelatedDepartments,
  createRelatedTeams,
  createRelatedEmployees,
} from '@src/api/analyticsDashboards'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { pathToUrl } from '@src/utils/router'
import {
  WithEntity,
  useOrgEntity,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'

interface SaveButtonProps {
  tabs: TabBarTableNavigationInterface[]
}

type LocationState = WithEntity<{
  backUrl: string
  state: WithEntity<{ backUrl: string }>
}>

export const SubmitButton = connect(({ tabs }: SaveButtonProps) => {
  const location = useLocation<LocationState>()
  const { values, submit } = useLapeContext<AnalyticsDashboardInterface>()
  const { navigateReplaceWithEntity } = useOrgEntity()

  const tabIndex = tabs.findIndex(tab => matchPath(location.pathname, tab.path!))
  const isLastTab = tabIndex >= tabs.length - 1

  if (!isLastTab) {
    return null
  }

  const afterSubmit = (result: { id?: number | string }) => {
    if (values.related_teams?.length) {
      createRelatedTeams(result.id!, values.related_teams[0].id)
    } else if (values.related_departments?.length) {
      createRelatedDepartments(result.id!, values.related_departments[0].id)
    } else if (values.related_employees?.length) {
      createRelatedEmployees(result.id!, values.related_employees[0].id)
    } else {
      createRelatedCompany(result.id!)
    }
  }

  // could be in state.state because this is a 2 step form
  const backUrl = location.state?.backUrl || location.state?.state?.backUrl

  return (
    <NewSaveButtonWithPopup
      hideWhenNoChanges={false}
      onAfterSubmit={res => {
        if (!values.id && res.id) {
          afterSubmit(res)
        }

        if (backUrl) {
          navigateReplaceWithEntity(backUrl, { dashboard: res })
        } else {
          navigateReplace(
            pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD, { id: res.id }),
          )
        }
      }}
      onClick={submit}
      successText={
        values.id ? 'Dashboard successfully updated' : 'Dashboard successfully added'
      }
      useValidator
    >
      Confirm
    </NewSaveButtonWithPopup>
  )
})
