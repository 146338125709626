import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  chain,
  Flex,
  Header,
  HStack,
  IconButton,
  Popup,
  Separator,
  Text,
  Token,
  Widget,
} from '@revolut/ui-kit'
import styled from 'styled-components'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  getCVScreeningSession,
  navigateToCVScreening,
} from '@src/pages/Forms/CVScreening/utils'
import { pathToUrl } from '@src/utils/router'
import { useLocation, useParams } from 'react-router-dom'
import { SwitchItemsSidebar } from '@components/SwitchItemsSidebar/SwitchItemsSidebar'

type Props = {
  currentNumber: number
  total: number
  children: React.ReactNode
  onNext: () => void
  onPrev: () => void
  disablePrev?: boolean
}

const Wrap = styled(Widget)`
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
`

const CvScreeningHeader = ({
  currentNumber,
  total,
  children,
  onNext,
  onPrev,
  disablePrev,
}: Props) => {
  const location = useLocation()
  const [openSidebar, setOpenSidebar] = useState(false)
  const params = useParams<{ id: string }>()
  const session = getCVScreeningSession()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const candidates = session.candidates

  const onClickSummary = () => {
    navigateTo(ROUTES.FORMS.CV_SCREENING_SUMMARY, undefined, true)
  }

  const sidebarItems = useMemo(
    () =>
      candidates?.map(item => ({
        id: +item.id,
        avatar: {
          id: +item.id,
          name: item.name,
        },
      })) || [],
    [candidates],
  )

  return (
    <Wrap>
      <SwitchItemsSidebar
        title="Candidates"
        items={sidebarItems}
        isOpen={openSidebar}
        onClose={() => setOpenSidebar(false)}
        selectedIndex={currentNumber - 1}
        onSelect={item => {
          navigateToCVScreening(item.id, location)
        }}
        avatarUrlFunc={id => pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id })}
        useLayout
      />

      <Popup
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        variant="bottom-sheet"
      >
        <Header variant="compact">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Are you sure you want to finish CV screening?</Header.Title>
        </Header>
        <Popup.Actions>
          <Button
            data-testid="no"
            variant="secondary"
            onClick={() => setOpenConfirmation(false)}
          >
            No, continue CV screening
          </Button>
          <Button onClick={onClickSummary} useIcon="BalanceSheet">
            Yes, go to Summary
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              if (!session.candidates?.length || session.candidates?.length === 1) {
                navigateTo(
                  session.referrerPath ||
                    pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: params.id }),
                )
                return
              }

              navigateTo(session.referrerPath || ROUTES.RECRUITMENT.CANDIDATES)
            }}
            useIcon="Profile"
          >
            Yes, go to Candidates
          </Button>
        </Popup.Actions>
      </Popup>

      <Flex justifyContent="space-between" alignItems="center" p="s-16">
        <HStack gap="s-8" align="center">
          <IconButton
            useIcon="Cross"
            size={16}
            onClick={() => setOpenConfirmation(true)}
          />
          <Text variant="primary">
            {chain(
              'CV Screening',
              <Text color={Token.color.greyTone50}>
                {currentNumber}/{total}
              </Text>,
            )}
          </Text>
        </HStack>

        {!!candidates?.length && candidates.length > 1 && (
          <HStack gap="s-8">
            <IconButton
              useIcon="ChevronLeft"
              size={24}
              onClick={onPrev}
              disabled={disablePrev}
              color={disablePrev ? Token.color.greyTone10 : undefined}
            />
            <IconButton useIcon="ChevronRight" size={24} onClick={onNext} />
            <IconButton useIcon="Menu" size={24} onClick={() => setOpenSidebar(true)} />
          </HStack>
        )}
      </Flex>
      <Separator />
      <Box p="s-16">{children}</Box>
    </Wrap>
  )
}

export default CvScreeningHeader
