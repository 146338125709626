import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/EmployeePerformance/Form'
import { connect } from 'lape'
import { Bar, Box, Flex, Sticky } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import * as ProbationDeliverables from '@src/pages/Forms/Probation/Deliverables'
import * as KPIDeliverables from '@src/pages/Forms/Probation/KPI'
import PerformanceValidationDialog from '@src/features/Popups/PerformanceValidationDialog'
import * as ProbationSkills from '@src/pages/Forms/Probation/Skills'
import * as ProbationCultureFit from '@src/pages/Forms/Probation/CultureFit'
import * as ProbationSummary from '@src/pages/Forms/Probation/Summary'
import { probationReviewRequests } from '@src/api/probationReview'
import NextButton from '@src/pages/Forms/EmployeePerformance/components/NextButton'
import SubmitButton from '@src/pages/Forms/EmployeePerformance/components/SumbitButton'
import Component from '@src/pages/Forms/EmployeePerformance/components/Component'
import SaveDraftButton from '@src/pages/Forms/EmployeePerformance/components/SaveDraftButton'
import Navigation from '@src/pages/Forms/EmployeePerformance/components/Navigation'
import PerformanceCycleClosed from '@components/ScorecardGeneral/PerformanceCycleClosed'
import { TabsInterface } from '@src/interfaces/data'
import PerformancePage from '@src/pages/Forms/EmployeePerformance/components/PerformancePage'
import Page404 from '@src/pages/Page404/Page404'
import PerformanceErrorWidget from '@src/pages/Forms/EmployeePerformance/components/PerformanceErrorWidget'
import { usePutFirstScorecardOpening } from '@src/utils/performance'

const getTabs = (
  params: {
    id: string
    employeeId?: string
  },
  isKPIDeliverables?: boolean,
  isCultureValues?: boolean,
): TabsInterface<ReviewScorecardInterface>[] => [
  {
    title: 'Deliverables',
    path: ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES,
    to: pathToUrl(ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES, params),
    validator: {},
    component: isKPIDeliverables
      ? KPIDeliverables.default
      : ProbationDeliverables.default,
    invalidTabMessage: () => (
      <PerformanceValidationDialog type={PerformanceReviewTypes.deliverables} />
    ),
    visibilityCheck: () => true,
  },
  {
    title: 'Skills',
    path: ROUTES.FORMS.PROBATION_REVIEW.SKILLS,
    to: pathToUrl(ROUTES.FORMS.PROBATION_REVIEW.SKILLS, params),
    validator: ProbationSkills.validator,
    component: ProbationSkills.default,
    invalidTabMessage: () => (
      <PerformanceValidationDialog type={PerformanceReviewTypes.skills} />
    ),
    visibilityCheck: data => !!data?.review_data?.functional_skills,
  },
  {
    title: 'Culture fit',
    path: ROUTES.FORMS.PROBATION_REVIEW.CULTURE_FIT,
    to: pathToUrl(ROUTES.FORMS.PROBATION_REVIEW.CULTURE_FIT, params),
    validator: ProbationCultureFit.validator,
    component: ProbationCultureFit.default,
    invalidTabMessage: () => (
      <PerformanceValidationDialog
        type={
          isCultureValues
            ? PerformanceReviewTypes.cultureValuesFit
            : PerformanceReviewTypes.cultureFit
        }
      />
    ),
    visibilityCheck: data =>
      !!data?.review_data?.culture_values || !!data?.review_data?.culture_skills,
  },
  {
    title: 'Summary',
    path: ROUTES.FORMS.PROBATION_REVIEW.SUMMARY,
    to: pathToUrl(ROUTES.FORMS.PROBATION_REVIEW.SUMMARY, params),
    validator: ProbationSummary.validator,
    component: ProbationSummary.default,
    invalidTabMessage: () => (
      <PerformanceValidationDialog type={PerformanceReviewTypes.summary} />
    ),
    visibilityCheck: () => true,
  },
]

const Probation = connect(() => {
  const params = useParams<{ id: string; employeeId?: string }>()
  const { values } = useLapeContext<ReviewScorecardInterface>()
  usePutFirstScorecardOpening(values, ReviewCategory.Probation)

  if (!values.review_data) {
    return <PerformanceErrorWidget />
  }

  const isKPIDeliverables = !!values.review_data.kpis_section
  const isCultureValues = !!values.review_data.culture_values

  const tabs = getTabs(params, isKPIDeliverables, isCultureValues).filter(tab =>
    tab.visibilityCheck(values),
  )

  if (!values.reviewed_employee?.id) {
    return <Page404 />
  }

  return (
    <PerformancePage>
      {!values.current_user_can_edit_review ? (
        <PerformanceCycleClosed employeeId={values.reviewed_employee.id} />
      ) : (
        <>
          <Box mb="s-16">
            <Navigation tabs={tabs} />
          </Box>
          <Flex flex="1 0">
            <Switch>
              {tabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  <Component
                    tab={tab}
                    employeeId={values.reviewed_employee.id!}
                    id={values.id}
                  />
                </Route>
              ))}
            </Switch>
          </Flex>
          <Sticky bottom={24}>
            <Flex justifyContent="center" width={628}>
              <Bar>
                <SaveDraftButton tabs={tabs} type="probation-review" />
                <SubmitButton
                  tabs={tabs}
                  type="probation-review"
                  notificationMessage="Probation review submitted"
                />
                <NextButton tabs={tabs} />
              </Bar>
            </Flex>
          </Sticky>
        </>
      )}
    </PerformancePage>
  )
})

const ProbationForm = () => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()

  return (
    <Form
      api={probationReviewRequests}
      storageKey={`${ReviewCategory.Probation}-${id}-${employeeId}`}
    >
      <Probation />
    </Form>
  )
}

export default connect(ProbationForm)
