import React, { useState } from 'react'
import { Button, BottomSheet, Header, Input } from '@revolut/ui-kit'

import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import {
  getFieldDataByIndexedKey,
  getFieldInfoFromIndexedKey,
  getFieldName,
  IndexedFieldKey,
  removeField,
} from './Fields/common'

export const useFieldActionsPopup = (values: DocumentsTemplatesInterface) => {
  const [fieldId, setFieldId] = useState<IndexedFieldKey>()
  const [fieldNewName, setFieldNewName] = useState<string>()
  const [activePopup, setActivePopup] = useState<'rename' | 'delete'>()

  const showRename = (id: IndexedFieldKey) => {
    setFieldId(id)
    setActivePopup('rename')
  }
  const showDelete = (id: IndexedFieldKey) => {
    setFieldId(id)
    setActivePopup('delete')
  }
  const hide = () => setActivePopup(undefined)

  if (!fieldId) {
    return {
      component: null,
      showRename,
      showDelete,
      hide,
    }
  }
  const { type, idx } = getFieldInfoFromIndexedKey(fieldId)
  const fieldData = getFieldDataByIndexedKey(fieldId, values)
  const fieldName = getFieldName(fieldData, type, idx)

  return {
    component: (
      <>
        <BottomSheet open={activePopup === 'rename'} onClose={hide}>
          <Header>
            <Header.Title>Rename {fieldName}</Header.Title>
          </Header>
          <Input
            label="Enter new name"
            value={fieldNewName}
            onChange={e => {
              setFieldNewName(e.currentTarget.value)
            }}
          />
          <BottomSheet.Actions horizontal>
            <Button onClick={hide} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (fieldNewName) {
                  fieldData.name = fieldNewName
                }
                hide()
              }}
              disabled={!fieldNewName}
              elevated
            >
              Confirm
            </Button>
          </BottomSheet.Actions>
        </BottomSheet>
        <BottomSheet open={activePopup === 'delete'} onClose={hide}>
          <Header>
            <Header.Title>Are you sure you want to delete {fieldName}?</Header.Title>
          </Header>
          <BottomSheet.Actions horizontal>
            <Button onClick={hide} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                removeField(fieldId, values)
                hide()
              }}
              elevated
            >
              Confirm
            </Button>
          </BottomSheet.Actions>
        </BottomSheet>
      </>
    ),
    showRename,
    showDelete,
    hide,
  }
}
