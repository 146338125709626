import React, { useCallback, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  Header,
  Item,
  ItemSkeleton,
  Popup,
  Search,
  StatusWidget,
  Sticky,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { useGetJobPosting, useGetJobPostings } from '@src/api/jobPosting'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { removeUnderscore } from '@src/utils/string'
import upperFirst from 'lodash/upperFirst'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { FilterByInterface } from '@src/interfaces/data'
import { debounce } from 'lodash'
import JobPostingContent from '@src/pages/Forms/JobPostingFlow/components/JobPostingContent'
import PageLoading from '@src/components/PageLoading/PageLoading'

enum ArchivedStatus {
  archived = 'archived',
}

type ApprovalStatus = ApprovalStatuses | ArchivedStatus

const getJobPostingStatusColor = (status: ApprovalStatus) => {
  switch (status) {
    case 'approved':
      return Token.color.green
    case 'pending':
      return Token.color.orange
    case 'rejected':
      return Token.color.red
    case 'archived':
    default:
      return Token.color.greyTone50
  }
}

const getJobPostingStatusIcon = (status: ApprovalStatus) => {
  switch (status) {
    case 'approved':
      return 'Check'
    case 'pending':
      return 'Time'
    case 'rejected':
      return 'Reverted'
    case 'archived':
      return 'Archive'
    default:
      return undefined
  }
}

type JobPostingItemProps = {
  jobPosting: JobPostingInterface
  onClick: (jobPosting: JobPostingInterface) => void
}

const JobPostingItem = ({ jobPosting, onClick }: JobPostingItemProps) => {
  return (
    <Item
      use="button"
      key={jobPosting.id}
      onClick={() => {
        onClick(jobPosting)
      }}
    >
      <Item.Avatar>
        <Avatar color={Token.color.greyTone50} useIcon="Newspaper">
          <Avatar.Badge
            useIcon={getJobPostingStatusIcon(jobPosting.approval_status)}
            bg={getJobPostingStatusColor(jobPosting.approval_status)}
            variant="outlined"
          />
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{jobPosting.name}</Item.Title>
        <Item.Description>
          <Text color={getJobPostingStatusColor(jobPosting.approval_status)}>
            {upperFirst(removeUnderscore(jobPosting.approval_status))}
          </Text>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

const SelectJobPostingWidget = () => {
  return (
    <StatusWidget>
      <StatusWidget.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D021.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D021@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D021@3x.png',
        }}
      />
      <StatusWidget.Title>Select a job posting</StatusWidget.Title>
    </StatusWidget>
  )
}

const useSpecialisationOptions = (specialisationId?: string | number) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionInterface[]>()
  const optionsData = useFetchOptions<OptionInterface>(selectorKeys.specialisations)
  const options = (optionsData.options ?? []).map(({ value }) => value)
  if (specialisationId && options.length && !selectedOptions) {
    setSelectedOptions(options.filter(({ id }) => id === specialisationId))
  }
  return {
    selectedOptions: selectedOptions ?? [],
    options,
    onFilterChange: (value?: OptionInterface[]) => {
      if (value) {
        setSelectedOptions(value)
      }
    },
  }
}

const useSearchValue = () => {
  const [searchValue, setSearchValue] = useState('')
  const createSearchFilter = (value: string) => ({
    columnName: 'search',
    filters: [
      {
        id: value,
        name: value,
      },
    ],
  })
  const [loadingSearchFilter, setLoadingSearchFilter] = useState(false)
  const [searchFilter, setSearchFilter] = useState<FilterByInterface>(
    createSearchFilter(searchValue),
  )
  const updateSearchFilter = useCallback(
    debounce((value: string) => {
      setSearchFilter(createSearchFilter(value))
      setLoadingSearchFilter(false)
    }, 500),
    [],
  )
  const onSearchValueChange = (value: string) => {
    setSearchValue(value)
    updateSearchFilter(value)
    setLoadingSearchFilter(true)
  }
  return {
    searchValue,
    searchFilter,
    loadingSearchFilter,
    onSearchValueChange,
  }
}

type JobPostingPopupProps = {
  confirmLabel: string
  renderJobPosting?: React.ElementType<{ jobPosting: JobPostingInterface }>
  specialisationId?: string | number
  titleLabel?: string
  onClose: () => void
  onConfirm: (jobPosting: JobPostingInterface) => void
}

const JobPostingPopup = ({
  confirmLabel,
  renderJobPosting = JobPostingContent,
  specialisationId,
  titleLabel = 'Job postings',
  onClose,
  onConfirm,
}: JobPostingPopupProps) => {
  const { searchValue, searchFilter, loadingSearchFilter, onSearchValueChange } =
    useSearchValue()
  const [selectedJobPosting, setSelectedJobPosting] = useState<JobPostingInterface>()
  const { selectedOptions, options, onFilterChange } =
    useSpecialisationOptions(specialisationId)
  const { data: jobPostingsData, isLoading: loadingJobPostings } = useGetJobPostings(
    selectedOptions.length
      ? [
          {
            columnName: 'specialisation__id',
            filters: selectedOptions.map(({ id }) => ({ id, name: String(id) })),
          },
          searchFilter,
        ]
      : [searchFilter],
  )
  const { data: jobPostingData, isLoading: loadingJobPosting } = useGetJobPosting(
    selectedJobPosting?.id,
  )
  const Component = renderJobPosting
  return (
    <Popup open size="lg" onClose={onClose}>
      <Header variant="compact">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>{titleLabel}</Header.Title>
      </Header>
      <Grid
        columns="minmax(343px, 343px) auto"
        columnGap="s-16"
        style={{
          height: 'calc(100% - 120px)',
        }}
      >
        <Widget height="100%" style={{ overflow: 'auto' }}>
          <Sticky p="s-16" top={0} bg={Token.color.widgetBackground} zIndex={100}>
            <VStack gap="s-16">
              <Search
                value={searchValue}
                placeholder="Search"
                onChange={onSearchValueChange}
              />
              <FilterButtonCheckboxSelect
                label="Role"
                options={options}
                searchable
                value={selectedOptions}
                onChange={onFilterChange}
              />
            </VStack>
          </Sticky>
          <Box>
            {loadingJobPostings || loadingSearchFilter ? (
              <ItemSkeleton />
            ) : (
              jobPostingsData?.map(jobPosting => (
                <JobPostingItem
                  key={jobPosting.id}
                  jobPosting={jobPosting}
                  onClick={() => {
                    setSelectedJobPosting(jobPosting)
                  }}
                />
              ))
            )}
          </Box>
        </Widget>
        <Box height="100%" style={{ overflow: 'auto' }}>
          {loadingJobPosting ? (
            <Flex alignItems="center" justifyContent="center" height="100%">
              <PageLoading />
            </Flex>
          ) : (
            <>
              {jobPostingData?.id ? (
                <Component jobPosting={jobPostingData} />
              ) : (
                <SelectJobPostingWidget />
              )}
            </>
          )}
        </Box>
      </Grid>
      <Popup.Actions horizontal>
        <Button
          variant="secondary"
          onClick={() => {
            onClose()
          }}
        >
          Close
        </Button>
        <Button
          disabled={!selectedJobPosting?.id}
          onClick={() => {
            if (jobPostingData) {
              onConfirm(jobPostingData)
              onClose()
            }
          }}
        >
          {confirmLabel}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

export default JobPostingPopup
