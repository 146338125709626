import React from 'react'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackStatus,
  InterviewResult,
} from '@src/interfaces/interviewTool'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import {
  Avatar,
  chain,
  Color,
  HStack,
  Item,
  ItemProps,
  Text,
  Token,
} from '@revolut/ui-kit'
import FeedbackActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/FeedbackActions'
import { formatDateTime } from '@src/utils/format'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'

const getFeedbackBadge = (
  result?: InterviewResult,
  status?: InterviewFeedbackStatus,
): { icon: ResolveIconType; color: Color; text?: string } => {
  if (status === 'rejected') {
    return {
      icon: 'Reverted',
      color: Token.color.deepGrey,
      text: undefined,
    }
  }

  switch (result) {
    case 'hire':
      return {
        icon: 'SocialLike',
        color: Token.color.green,
        text: 'Hire',
      }
    case 'strong_hire':
      return {
        icon: 'SocialLike',
        color: Token.color.green,
        text: 'Strong hire',
      }

    case 'no_hire':
      return {
        icon: 'SocialDislike',
        color: Token.color.red,
        text: 'No hire',
      }
    case 'strong_no_hire':
      return {
        icon: 'SocialDislike',
        color: Token.color.red,
        text: 'Strong no hire',
      }
  }

  return {
    icon: '16/SandWatch',
    color: Token.color.orange,
    text: 'Awaiting feedback',
  }
}

const getFeedbackDescription = (status?: InterviewFeedbackStatus, date?: string) => {
  if (!date) {
    return null
  }

  switch (status) {
    case 'rejected':
      return `Rejected on ${formatDateTime(date)}`

    case 'draft':
      return `Drafted on ${formatDateTime(date)}`

    case 'completed':
      return `Completed on ${formatDateTime(date)}`

    default:
      return null
  }
}

interface Props {
  onRefresh?: (onlyTable?: boolean) => void
  feedback: InterviewFeedbackInterface
  canCancel: boolean
  canAddFeedback: boolean
  onClick?: () => void
  disableActions?: boolean
  selected?: boolean
  prefix?: React.ReactNode
  itemProps?: ItemProps
}
export const StagesTableFeedback = ({
  feedback,
  canCancel,
  canAddFeedback,
  onRefresh,
  onClick,
  disableActions,
  selected,
  prefix,
  itemProps,
}: Props) => {
  const badge = getFeedbackBadge(feedback.result || undefined, feedback.status)
  const description = getFeedbackDescription(feedback.status, feedback.updated_date_time)

  return (
    <Item
      use={onClick ? 'button' : undefined}
      key={feedback.id}
      onClick={e => {
        e.preventDefault()
        onClick?.()
      }}
      aria-pressed={selected}
      aria-label={`Feedback ${feedback.interviewer?.display_name} #${feedback.id}`}
      {...itemProps}
    >
      {prefix}
      <Item.Avatar>
        <Avatar
          image={
            typeof feedback.interviewer!.avatar === 'string'
              ? feedback.interviewer!.avatar
              : feedback.interviewer!.avatar?.image_72
          }
        >
          <Avatar.Badge useIcon={badge.icon} backgroundColor={badge.color} />
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{feedback.interviewer!.display_name}</Item.Title>
        <Item.Description>
          {chain(
            badge.text && <Text color={badge.color}>{badge.text}</Text>,
            description && <Text color={Token.color.greyTone50}>{description}</Text>,
          )}
        </Item.Description>
      </Item.Content>

      <Item.Side>
        <HStack gap="s-16">
          {feedback.rating && (
            <PerformanceRatingLabelTag
              fontSize="caption"
              fontWeight={400}
              rating={feedback.rating}
            />
          )}
          {!disableActions && (canCancel || canAddFeedback) && (
            <div onClick={e => e.stopPropagation()}>
              <FeedbackActions
                feedback={feedback}
                canCancel={canCancel}
                canAddFeedback={canAddFeedback}
                onRefresh={onRefresh}
              />
            </div>
          )}
        </HStack>
      </Item.Side>
    </Item>
  )
}
